import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoLockClosed, IoMail, IoPerson} from 'react-icons/io5';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {ZULL_API, ZULL_DATETIME, ZULL_STRING} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import BasePageLayout from './BasePageLayout';
import Input from '../components-elements/Input';
import Checkbox from '../components-elements/Checkbox';
import WarningBanner from '../components-elements/WarningBanner';
import './PageRegister.css';
import RadioGroup from '../components-elements/RadioGroup';

export const accountPrerequisites = {
  usernameMin: 4,
  usernameMax: 14,
  passwordMin: 6,
  passwordMax: 14
};

const PageRegister = () => {
  const {t, i18n} = useTranslation();
  const {globalState} = useContext(GlobalContext);
  const {executeRecaptcha} = useGoogleReCaptcha();

  const [registerUsername, setRegisterUsername] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
  const [registerUsernameError, setRegisterUsernameError] = useState(false);
  const [registerEmailError, setRegisterEmailError] = useState(false);
  const [registerPasswordError, setRegisterPasswordError] = useState(false);
  const [registerPasswordConfirmError, setRegisterPasswordConfirmError] = useState(false);
  const [registerHowDidYouFindUs, setRegisterHowDidYouFindUs] = useState('');
  const [registerTOS, setRegisterTOS] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationPending, setRegistrationPending] = useState(false);

  const resendCooldown = 5 * 60; // 5 minutes
  const [remaining, setRemaining] = useState(resendCooldown);
  const [resendSuccess, setResendSuccess] = useState(false);
  let timer: any;
  let timerRegistered = false;
  let cooldown: number;

  const setTimer = () => {
    cooldown = resendCooldown;
    setRemaining(cooldown);
    timer = setInterval(() => {
      cooldown--;
      setRemaining(cooldown);
      if (cooldown <= 0) {
        clearInterval(timer);
        timerRegistered = false;
      }
    }, 1000);
    timerRegistered = true;
  };

  const resendEmail = () => {
    if (cooldown > 0) return;
    ZULL_API.POST({
      endpoint: 'account/new/resendverification',
      body: JSON.stringify({
        email: registerEmail,
        langCode: i18n.language.substring(0, 2)
      })
    }).then(res => {
      if (res.ok) {
        setTimer();
        setResendSuccess(true);
        setTimeout(() => setResendSuccess(false), 1000);
      }
    });
  };

  useEffect(() => () => {  // fires on component unmount
    if (timerRegistered) {
      clearInterval(timer);
      timerRegistered = false;
    }
  }, []);

  if (globalState.isLoggedIn) return (
    <BasePageLayout title={t('account_register')}>
      <WarningBanner type="error">
        {t('account_error_alreadyloggedin')}
      </WarningBanner>
    </BasePageLayout>
  );

  if (registrationPending) return (
    <BasePageLayout title={t('account_register')}>
      <WarningBanner type="info">
        <div>
          {t('account_registration_pending')}<br />
          <br />
          {t('account_registration_pending_resend')}<br />
          {t('account_registration_pending_resend_wait')} {ZULL_DATETIME.timeFormat(remaining, ZULL_DATETIME.TimeFormat.mmss)}.<br />
          {resendSuccess ? t('success') : <button className="button-a" type="button" onClick={resendEmail}>{t('account_registration_pending_resend_link')}</button>}<br />
        </div>
      </WarningBanner>
    </BasePageLayout>
  );

  const register = () => {
    let isError = false;
    let newErrorMessage = '';
    setRegisterUsernameError(!registerUsername || registerUsername.length < accountPrerequisites.usernameMin || registerUsername.length > accountPrerequisites.usernameMax);
    if (!ZULL_STRING.isAlphaNumeric(registerUsername)) {
      isError = true;
      newErrorMessage = t('account_error_username_alphanumeric');
      setRegisterUsernameError(true);
    }
    setRegisterEmailError(!registerEmail || !ZULL_STRING.isEmail(registerEmail));
    setRegisterPasswordError(!registerPassword || registerPassword.length < accountPrerequisites.passwordMin || registerPassword.length > accountPrerequisites.passwordMax);
    setRegisterPasswordConfirmError(registerPasswordConfirm !== registerPassword);
    if (!registerTOS) newErrorMessage = t('account_error_accepttos');
    setErrorMessage(newErrorMessage);
    if (isError ||
      !registerUsername || registerUsername.length < accountPrerequisites.usernameMin || registerUsername.length > accountPrerequisites.usernameMax ||
      !registerEmail ||
      !registerPassword || registerPassword.length < accountPrerequisites.passwordMin || registerPassword.length > accountPrerequisites.passwordMax ||
      registerPasswordConfirm !== registerPassword ||
      !registerTOS) return;

    if (!executeRecaptcha) return;
    executeRecaptcha().then(res => ZULL_API.POST({
      endpoint: 'account',
      body: JSON.stringify({
        user: registerUsername,
        pass: registerPassword,
        email: registerEmail,
        captchaToken: res,
        howdidyoufindus: registerHowDidYouFindUs,
        langCode: i18n.language.substring(0, 2)
      })
    })).then(res => {
      if (!res.ok) {
        newErrorMessage = '';
        if (res.body?.toLowerCase().includes('captcha')) newErrorMessage = t('account_error_captcha');
        if (res.status === 500) newErrorMessage = t('error_generic');
        if (res.body?.toLowerCase().includes('username taken')) {
          newErrorMessage = t('account_error_usertaken');
          setRegisterUsernameError(true);
        }
        if (res.body?.toLowerCase().includes('email taken')) {
          newErrorMessage = t('account_error_emailtaken');
          setRegisterEmailError(true);
        }
        setErrorMessage(newErrorMessage);
        return;
      }
      setRegistrationPending(true);
      setTimer();
    });
  };

  return (
    <BasePageLayout title={t('account_register')}>
      <div id="page-register-content">
        <WarningBanner type="error" show={!!errorMessage}>
          {errorMessage}
        </WarningBanner>
        <form onSubmit={e => {e.preventDefault(); register();}}>
          <Input label={t('account_username')} text={registerUsername} onChange={e => setRegisterUsername(e.target.value.trim())}
            icon={<IoPerson />} isError={registerUsernameError} id="signup-username"
            minLength={accountPrerequisites.usernameMin} maxLength={accountPrerequisites.usernameMax}
            autocomplete="username" />
          <Input label={t('account_email')} text={registerEmail} onChange={e => setRegisterEmail(e.target.value.trim())}
            icon={<IoMail />} isError={registerEmailError} type="email"
            id="signup-email" autocomplete="email" />
          <Input label={t('account_password')} text={registerPassword} onChange={e => setRegisterPassword(e.target.value.trim())}
            icon={<IoLockClosed />} isError={registerPasswordError} type="password"
            id="signup-password" minLength={accountPrerequisites.passwordMin} maxLength={accountPrerequisites.passwordMax}
            autocomplete="new-password" />
          <Input label={t('account_password_confirm')} text={registerPasswordConfirm} onChange={e => setRegisterPasswordConfirm(e.target.value.trim())}
            icon={<IoLockClosed />} isError={registerPasswordConfirmError} type="password"
            id="signup-password-confirm" autocomplete="new-password" />
          <RadioGroup groupId="how-did-you-find-us"
            title={t('account_registration_howdidyoufindus')} options={[
              {id: 'google', label: 'Google'},
              {id: 'youtube', label: 'YouTube'},
              {id: 'tiktok', label: 'TikTok'},
              {id: 'top100', label: t('account_registration_howdidyoufindus_top100')},
              {id: 'recommendation', label: t('account_registration_howdidyoufindus_recommendation')}
            ]} value={registerHowDidYouFindUs}
            onChange={e => setRegisterHowDidYouFindUs(e)} />
          <Checkbox checked={registerTOS} onChange={e => setRegisterTOS(e.target.checked)} id="signup-accepttos">
            {t('account_accepttos')} <Link to="/tos">{t('nav_tos')}</Link>, <Link to="/privacy">{t('nav_privacy')}</Link> & <Link to="/refundpolicy">{t('nav_refundpolicy')}</Link>
          </Checkbox>
          <div id="recaptcha-container" />
          <button type="submit" className="button primary">
            {t('account_register')}
          </button>
        </form>
      </div>
    </BasePageLayout>
  );
};

export default PageRegister;
